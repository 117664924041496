import React, { FunctionComponent, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { REASONS } from '@ads-bread/shared/bread/util';
import { FormErrorContext } from '../APIForm';
import CallOut from '../CallOut';
import { Button } from '../Button';
import { useFeatureFlags } from '../FeatureFlagsContext';
import { TextLink } from '../TextLink';
import { useAuthentication } from '../AuthenticationMachineContext';
import {
  RoutePathCondition,
  useRouteMachineService,
} from '../RouteMachineService';

export type Action =
  | 'sendCode'
  | 'authenticate'
  | 'review'
  | 'id_verification'
  | 'buyerConflict'
  | 'eligibility';

export function withLocation(reason: string, location?: Action): string {
  return getKey({ reason, location });
}

const PaymentError = (): JSX.Element => {
  const { enableSplitPayAuthEnhancement } = useFeatureFlags();
  const { routeWithCondition } = useRouteMachineService();
  const { route } = useRouter();
  const isInStore = route.startsWith('/in-store');

  return enableSplitPayAuthEnhancement && isInStore ? (
    <FormattedMessage
      defaultMessage={`<b>We could not authorize your payment.</b> You may need to <addPaymentLink>update your payment method.</addPaymentLink>`}
      description="Form error message Virtual Card failed with payment method update"
      values={{
        addPaymentLink: (chunks: React.ReactNode) => (
          <TextLink
            href="#"
            onClick={() => {
              routeWithCondition(RoutePathCondition.Payment);
            }}
            testID="vc-error-add-payment-link"
          >
            {chunks}
          </TextLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage
      defaultMessage="<b>We are unable to validate your payment.</b>{br}Try again or use another payment method to continue."
      description="Form error message on 'We are unable to validate your payment' from 'Payments'"
      values={{
        br: <br />,
      }}
    />
  );
};

export const ERROR_MAPPING: Record<
  string,
  (attemptsRemaining?: number) => JSX.Element
> = {
  [withLocation(REASONS.Buyer.BUYER_CONFLICT, 'buyerConflict')]: () => {
    const { logout } = useAuthentication();

    return (
      <FormattedMessage
        defaultMessage="Please verify your information is accurate and try again."
        description="Form error message on 'buyerConflict'"
        values={{
          ...formattingValues,
          logoutLink: (chunks: React.ReactNode) => (
            <Button theme="text" type="button" onClick={logout}>
              {chunks}
            </Button>
          ),
        }}
      />
    );
  },
  [withLocation(REASONS.Buyer.BUYER_VALIDATION, 'eligibility')]: () => (
    <FormattedMessage
      defaultMessage="Please verify your information is accurate and try again."
      description="Form error message cannot update IIN when credit report IIN is present"
    />
  ),
  [withLocation(REASONS.Buyer.TOO_MANY_UNVERIFIED_CODES_SENT, 'sendCode')]:
    () => (
      <FormattedMessage
        defaultMessage="You have too many outstanding codes, please try again in 1 hour."
        description="Form error message on too many unverified attempts"
      />
    ),
  [withLocation(REASONS.Auth.REQUEST_VALIDATION, 'sendCode')]: () => (
    <FormattedMessage
      defaultMessage="We were unable to send a code to the mobile number provided. Please try again with a valid mobile phone number."
      description="Form error message on 'invalid phone type' from 'Send Code'"
    />
  ),
  [withLocation(REASONS.Auth.TOO_LOGIN_MANY_ATTEMPTS, 'sendCode')]: () => (
    <FormattedMessage
      defaultMessage="Your account is locked. Please wait 15 minutes and then request a new verification code."
      description="Form error message on 'too many attempts' from 'Send Code'"
    />
  ),
  [withLocation(REASONS.Auth.INVALID_TOKEN, 'authenticate')]: () => (
    <FormattedMessage
      defaultMessage="The verification code is incorrect. Please try again or request a new code."
      description="Form error message on 'invalid token' from 'Authenticate'"
    />
  ),
  [withLocation(REASONS.Auth.TOO_LOGIN_MANY_ATTEMPTS, 'authenticate')]: () => (
    <FormattedMessage
      defaultMessage="You’ve made too many attempts. Please wait 30 minutes and then request a new verification code."
      description="Form error message on 'too many attempts' from 'Authenticate'"
    />
  ),
  [withLocation(REASONS.PAYMENTS.INVALID_PAYMENT_METHOD_ERROR, 'review')]:
    PaymentError,
  [withLocation(REASONS.PAYMENTS.WRITE_CARD_ACCOUNT_ERROR, 'review')]:
    PaymentError,
  [withLocation(REASONS.PAYMENTS.GET_CARD_CONNECTION_ERROR, 'review')]:
    PaymentError,
  [withLocation(REASONS.PAYMENTS.TRANSACTION_ERROR, 'review')]: PaymentError,
  [withLocation(REASONS.PAYMENTS.GET_PAYMENT_METHOD_ERROR, 'review')]:
    PaymentError,
  [withLocation(REASONS.PAYMENTS.AUTHORIZE_PAYMENT_ERROR, 'review')]:
    PaymentError,
  [withLocation(
    REASONS.DOWN_PAYMENT_AUTH.DECLINED_RETRY_PAYMENT_METHOD,
    'review'
  )]: PaymentError,
  [withLocation(REASONS.VIRTUAL_CARD.FAILED, 'review')]: (
    attemptsRemaining
  ) => (
    <FormattedMessage
      defaultMessage={`Something went wrong, please try again. You have {attemptsRemaining} {attemptsRemaining, plural,
          =1 {attempt remaining.}
          other {attempts remaining.}
      }`}
      description="Form error message Virtual Card failed"
      values={{
        attemptsRemaining: attemptsRemaining,
      }}
    />
  ),
  [withLocation(REASONS.ID_VERIFICATION.TOO_BLURRY, 'id_verification')]: (
    attemptsRemaining
  ) => (
    <FormattedMessage
      defaultMessage="The photo you uploaded is too blurry. Please retake it. You have {attemptsRemaining} upload attempts left."
      description="ID Verification evaluation error message too blurry"
      values={{
        attemptsRemaining: attemptsRemaining,
      }}
    />
  ),
  [withLocation(
    REASONS.ID_VERIFICATION.SIDE_SUBMITTED_TWICE,
    'id_verification'
  )]: (attemptsRemaining) => (
    <FormattedMessage
      defaultMessage="You submitted two photos showing the same side of your ID. Please upload the other side. You have {attemptsRemaining} upload attempts left."
      description="ID Verification evaluation error message side submitted twice"
      values={{
        attemptsRemaining: attemptsRemaining,
      }}
    />
  ),
  [withLocation(REASONS.ID_VERIFICATION.EXPIRED, 'id_verification')]: (
    attemptsRemaining
  ) => (
    <FormattedMessage
      defaultMessage="Please upload the photo of an ID that has not expired. You have {attemptsRemaining} upload attempts left."
      description="ID Verification evaluation error message expired"
      values={{
        attemptsRemaining: attemptsRemaining,
      }}
    />
  ),
  [withLocation(REASONS.ID_VERIFICATION.TOO_MUCH_GLARE, 'id_verification')]: (
    attemptsRemaining
  ) => (
    <FormattedMessage
      defaultMessage="The photo you uploaded has glare spots. Please retake it. You have {attemptsRemaining} upload attempts left."
      description="ID Verification evaluation error message too much glare"
      values={{
        attemptsRemaining: attemptsRemaining,
      }}
    />
  ),
  [withLocation(REASONS.ID_VERIFICATION.PORTRAIT_NOT_CLEAR, 'id_verification')]:
    (attemptsRemaining) => (
      <FormattedMessage
        defaultMessage="The photo you uploaded is unclear or out of focus. Please retake it. You have {attemptsRemaining} upload attempts left."
        description="ID Verification evaluation error message portrait not clear"
        values={{
          attemptsRemaining: attemptsRemaining,
        }}
      />
    ),
  [withLocation(
    REASONS.ID_VERIFICATION.BARCODE_NOT_DETECTED,
    'id_verification'
  )]: (attemptsRemaining) => (
    <FormattedMessage
      defaultMessage="The barcode in your ID photo is unclear or incomplete. Please take, select and upload a new photo that has the entire barcode clearly visible. You have {attemptsRemaining} upload attempts left."
      description="ID Verification evaluation error message portrait not clear"
      values={{
        attemptsRemaining: attemptsRemaining,
      }}
    />
  ),
};

const getUnexpectedError = () => (
  <FormattedMessage
    defaultMessage="Looks like something went wrong."
    description="Unexpected form submit error"
  />
);

function getKey({ reason, location }: FormErrorContext): string {
  return [reason, location].filter(Boolean).join('|');
}

function getErrorMessage(error: FormErrorContext): JSX.Element {
  const key = getKey(error);
  const getMessage = ERROR_MAPPING[key] || getUnexpectedError;

  return getMessage(error.attemptsRemaining);
}

type Props = {
  error: FormErrorContext;
};

export const SubmitError: FunctionComponent<Props> = ({ error }) => (
  <CallOut type="Error" dataTestId={FORM_ERROR_TEST_ID}>
    {getErrorMessage(error)}
  </CallOut>
);

export const FORM_ERROR_TEST_ID = 'form-submit-error';

const formattingValues: Record<string, any> = {
  strong: (chunks: ReactNode) => (
    <span className="underline font-bold">{chunks}</span>
  ),
};
